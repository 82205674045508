.gridcard {
    background: #FFFFFF;
    border-radius: 24px;
    margin-top: 12px;
}

.upcomingworkshop {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
    text-align: center;
    color: #F19202;
}

.tableheaders {
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    align-items: flex-end;
    text-transform: capitalize;
    color: #9E9D9C;
    border-bottom: 1px solid #E9E9E9;

}

.tablebodycolumn1 {
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: normal;
    color: #000000;
}

.tablebodycolumn1 td {
    vertical-align: middle;
    text-align: center;
}

.countbox {
    background-color: rgba(245.51719844341278, 247.13326692581177, 248.62500607967377, 1) !important;
    height: 49px;
    border-radius: 12px;
    width: 145px;
}

.workshopcard {
    filter: drop-shadow(16px 22px 29px rgba(0, 0, 0, 0.07));
    padding-top: 34px;
}

@media (min-width: 200px) and (max-width: 820px) {
    .countbox {
        background-color: rgba(245.51719844341278, 247.13326692581177, 248.62500607967377, 1) !important;
        height: 49px;
        border-radius: 12px;
        position: unset;
        margin-top: 25px;
        width: 145px;
    }
}


.btnres {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 157px;
    height: 49px;
    background: rgba(241, 146, 2, 0.1);
    border-radius: 12px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F19202;
}

.copyicon {
    width: 12px;
    height: 12px;
}

.deleteicon {
    width: 12px;
    height: 12px;
}

.subh5txt1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
    color: #9E9D9C;
}

.noofparttextmodel {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    color: #F19202;
}

.popuptext {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #9E9D9C;
}

.delbooking {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    text-align: center;
    color: #000000;
}

.delsure {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #9E9D9C;
}

.keepbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    border: none;
    width: 176px;
    background: #F19202;
    border-radius: 14px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.yesdel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 176px;
    border: none;
    background: rgba(246, 91, 91, 0.14);
    border-radius: 14px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #F65B5B;
}

.workshpdel {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    /* identical to box height */

    text-align: center;

    color: #000000;

}

.wrkshpdelsub {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;

    color: #9E9D9C;
}

.modal-number {
    padding: 40px 30px;
}

.UpcomingWorkshops {
    position: relative;
    z-index: 111;
}