.modalback {
    background: #FFFFFF;
    border-radius: 36px !important;
}

.logo {
    width: 123.6px;
    height: 74.21px;
    left: 316px;
    top: 10px;
}

.StaffPortalLink {
    text-align: right;
}

a {
    color: #FFFFFF;
    text-decoration: none;
}

a:hover {
    color: #FFFFFF;
}

.link-dark {
    text-decoration: none !important;
}

.staffportal {

    height: 19px;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 119.22%;
    /* identical to box height, or 19px */


    align-items: center;
    text-align: right;

    color: #000000;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.mainmodalbody {

    width: 700px;

    background: #FFFFFF;
    /* box-shadow: 12px 16px 24px rgb(0 0 0 / 7%); */
    border-radius: 36px;
}

/* .modal.show .modal-dialog {
    margin-left: 315px;
    margin-top: 50px;
} */

.modalhead {



    left: 238px;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;

    text-align: center;
    color: #000000;
}

.buttontext {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;


    width: 176px;
    height: 59px;
    left: 572px;
    top: 181px;

    background: #F19202;
    border-radius: 14px;


    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    border: 0px;
}

.pophdtext {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    /* identical to box height */

    text-align: center;

    color: #000000;
}

.poheadsubtxt {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    text-align: center;

    color: #9E9D9C;
}

.modal-content {

    min-width: 900px;
    height: auto;
    border-radius: 36px !important;

}

@media (min-width: 200px) and (max-width: 768px) {
    .modal-content {

        min-width: 0px;
        height: auto;
        border-radius: 36px !important;

    }
}

.modal.show .modal-dialog {
    transform: none;
    justify-content: center;
}