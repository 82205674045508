.youraccount {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 600;
	font-size: 38px;
	line-height: 52px;
	color: #000000;
	flex: none;
	order: 0;
	flex-grow: 0;
}

.backcard {
	background-color: #FFFFFF !important;
	border-radius: 24px;
	background: radial-gradient(24.89% 169.46% at 56.05% 70.75%, rgba(241, 217, 2, 0.11) 0%, rgba(241, 217, 2, 0) 100%);
	border-radius: 18px;
	filter: drop-shadow(16px 22px 30px rgba(0, 0, 0, 0.05));
	padding: 18px;
}

.subtxbusiness {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 33px;
	color: #9E9D9C;
	flex: none;
	order: 2;
	flex-grow: 0;
}

.businessx {

	height: 52px;


	font-family: 'Manrope';
	font-style: normal;
	font-weight: 500;
	font-size: 38px;
	line-height: 52px;
	/* identical to box height */


	color: #F19202;
}

.btnframe {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 18px 10px;
	gap: 16px;
	border: none;

	width: 293px;
	height: 63px;

	background: #F19202;
	border-radius: 12px;



}

.btntxtupdate {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 800;
	font-size: 20px;
	line-height: 27px;

	align-items: center;

	color: #FFFFFF;
}

#btnupdateaccount:hover {
	color: #FFFFFF;
	text-decoration: none;
}

.AccountDetails {
	display: flex;
	justify-content: flex-end;
}