 .datePickerandtime-row {
     display: flex;
     border-radius: 50px;
     overflow: hidden;
 }

 .mission-details-block {
     max-width: 280px;
 }

 .mission-info h2 {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 600;
     font-size: 21px;
     line-height: 33px;
     display: flex;
     align-items: center;
     margin: 0;
     color: #FFFFFF;
 }

 .mission-info p {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 600;
     font-size: 18px;
     line-height: 25px;
     display: flex;
     align-items: center;
     color: #1E1E1C;
 }

 .mission-info p span {
     color: #FFFFFF;
 }

 .mission-input input {
     width: 100%;
     border-radius: 8px;
     height: 42px;
     margin-top: 12px;
     padding-left: 20px;
 }

 .mission-input p {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 600;
     font-size: 15px;
     line-height: normal;
     display: flex;
     align-items: center;
     color: #FFFFFF;
     margin-top: 11px;
 }



 .datePicker-column {
     width: 100%;
 }

 .mission-details-block {
     max-width: 280px;
     margin: 0 auto;
 }

 .timeslot-column {
     padding: 24px;
     width: 100%;
 }

 .mission-input input {
     outline: none;
     border: none;
 }

 .mission-input input:focus-visible {
     outline: none;
 }

 .datepicker-block {
     text-align: center;
 }

 .datePicker-column {
     background-color: #f19202;
     padding: 30px;
     width: 32%;
 }

 .datePicker-column .react-datepicker {
     background-color: transparent;
     border: none;
 }

 .datePicker-column .react-datepicker__header {
     background-color: transparent;
     border: none;
 }

 .datePicker-column .react-datepicker__year-read-view--down-arrow,
 .datePicker-column .react-datepicker__month-read-view--down-arrow,
 .datePicker-column .react-datepicker__month-year-read-view--down-arrow,
 .react-datepicker__navigation-icon::before {
     border-color: #000;
 }

 .datePicker-column .react-datepicker__navigation:hover *::before {
     border-color: #000;
 }

 .datePicker-column .react-datepicker__navigation {
     top: 14px;
 }

 .datePicker-column .react-datepicker__day--keyboard-selected,
 .datePicker-column .react-datepicker__month-text--keyboard-selected,
 .datePicker-column .react-datepicker__quarter-text--keyboard-selected,
 .datePicker-column .react-datepicker__year-text--keyboard-selected {
     border-radius: 100px;
     background-color: #ffffff;
     color: #000;
 }

 .datePicker-column .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .datePicker-column .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
 .datePicker-column .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .datePicker-column .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
 .datePicker-column .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .datePicker-column .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .datePicker-column .react-datepicker__year-text--in-range),
 .datePicker-column .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .datePicker-column .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .datePicker-column .react-datepicker__year-text--in-range) {
     background-color: rgb(255 255 255);
     color: #000;
     border-radius: 100px;
 }

 .datePicker-column .react-datepicker__day:hover,
 .datePicker-column .react-datepicker__month-text:hover,
 .datePicker-column .react-datepicker__quarter-text:hover,
 .datePicker-column .react-datepicker__year-text:hover {
     border-radius: 100px;
     background-color: #f0f0f0;
 }

 .datePicker-column .react-datepicker__day--selected,
 .datePicker-column .react-datepicker__day--in-selecting-range,
 .datePicker-column .react-datepicker__day--in-range,
 .datePicker-column .react-datepicker__month-text--selected,
 .datePicker-column .react-datepicker__month-text--in-selecting-range,
 .datePicker-column .react-datepicker__month-text--in-range,
 .datePicker-column .react-datepicker__quarter-text--selected,
 .datePicker-column .react-datepicker__quarter-text--in-selecting-range,
 .datePicker-column .react-datepicker__quarter-text--in-range,
 .datePicker-column .react-datepicker__year-text--selected,
 .datePicker-column .react-datepicker__year-text--in-selecting-range,
 .datePicker-column .react-datepicker__year-text--in-range {
     border-radius: 100px;
     background-color: #f0f0f0;
     color: #000;
 }

 .datePicker-column .react-datepicker__year-read-view--down-arrow,
 .datePicker-column .react-datepicker__month-read-view--down-arrow,
 .datePicker-column .react-datepicker__month-year-read-view--down-arrow,
 .datePicker-column .react-datepicker__navigation-icon::before {
     border-color: #000;
 }

 .timeslot-column {
     padding: 24px;
     width: 68%;
 }

 .timeslot-header {
     display: flex;
     margin-bottom: 20px;
     justify-content: space-between;
     align-items: center;
 }

 .timeslot-left h1 {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 500;
     font-size: 22px;
     line-height: normal;
     color: #F19202;
     margin: 0 0 3px;
 }

 .timeslot-left p {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 600;
     font-size: 16px;
     line-height: 25px;
     color: #9E9D9C;
 }

 .changelanguage-block p {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 600;
     font-size: 16px;
     line-height: 22px;
     text-align: right;
     color: #F19202;
 }

 .changelanguage-block {
     display: flex;
     flex-direction: column;
     justify-content: flex-end;
     align-items: flex-end;
 }

 .changelanguage-block {
     cursor: pointer;
 }

 .timeslot-list {
     display: flex;
     flex-flow: column wrap;
     height: 298px;
 }

 .timeslot-list-header {
     font-family: 'Manrope';
     font-style: normal;
     font-weight: 600;
     font-size: 18px;
     line-height: 25px;
     text-align: left;
     color: #000000;
     margin-bottom: 4px;
     margin-left: 5px;
     display: flex;
     justify-content: space-between;
 }

 .timeslot-list-header a {
     color: #F19202;
 }

 .timeslot {
     border: 1px solid #4E4957;
     border-radius: 6px;
     padding: 6px;
     text-align: center;
     cursor: pointer;
     width: calc(25% - 10px);
     margin-bottom: 10px;
     margin-left: 5px;
     margin-right: 5px;
 }

 .timeslot.booked {
     border: 1px solid #E9E9E9;
     background: #E9E9E9;
 }

 .timeslot.clicked {
     background: rgba(241, 146, 2, 0.2);
     color: #F19202;
     border: 1px solid rgba(241, 146, 2, 0.2);
 }

 .timeslot.makeBooking {
     background: #F19202;
     border: 1px solid #F19202;
     color: #FFFFFF;
 }

 .successfully-header {
     display: flex;
     align-items: center;
     width: 100%;
     justify-content: space-between;
 }

 @media only screen and (max-width: 1024px) {
     .timeslot-list {
         display: flex;
         flex-flow: column wrap;
         height: 380px;
     }
 }

 @media only screen and (max-width: 600px) {
     .timeslot-list {
         display: flex;
         flex-flow: column wrap;
         height: auto;
     }
 }