.rotating {
  animation: rotate;
  animation-duration: 0.8s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.pre_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(136 131 123 / 20%);
  backdrop-filter: blur(5px);
  z-index: 9;

  span.rotating_icon {
    width: 200px;
    height: 200px;
    background-image: url(../../assets/loader.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    animation: rotate;
    animation-duration: 0.8s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate((360deg));
  }
}
