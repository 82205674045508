.available-licenses-card {
    margin-top: 60px;
}

.available-licenses-card h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
    color: #000000;
    margin-bottom: 12px;
}

.available-licenses-card .purchlisbackcard {
    background-color: #FFFFFF !important;
    background: radial-gradient(24.89% 169.46% at 56.05% 70.75%, rgba(241, 217, 2, 0.11) 0%, rgba(241, 217, 2, 0) 100%);
    border-radius: 18px;
    padding: 17px;
    filter: drop-shadow(16px 22px 29px rgba(0, 0, 0, 0.05));
}

.available-licenses-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    gap: 12px;
    height: 62px;
    background: rgba(241, 146, 2, 0.1);
    border-radius: 12px;
    max-width: max-content;
}

.avaisnum {
    height: 52px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
    text-align: right;
    color: #F19202;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.avaisnum2 {
    height: 52px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
    text-align: right;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.avalis {
    height: 25px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #F19202;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.btmpurls {
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 10px;
    gap: 16px;
    height: 64px;
    background: #F19202;
    border-radius: 12px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    align-items: center;
    color: #FFFFFF;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.longtext {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #9E9D9C;
}

.longtextnew1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 33px;
    color: #9E9D9C;

}


.mdlsec1hdtxt {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 52px;
    text-align: center;
    color: #000000;
}

.mdlsec1subtxt {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    display: flex;
    align-items: flex-end;
    text-transform: capitalize;

    color: #9E9D9C;
}

.mdlsec1backlay {
    background: #F6F7F9;
    border-radius: 18px;
    /* height: 69px; */
}

.mdlsec1spnback {
    width: 39px;
    height: 43px;
    padding: 5px;
    background: rgba(242, 166, 51, 0.25);
    border-radius: 12px;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #000000;
}

.notext {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */


    align-items: center;
    text-align: center;

    color: #000000;
}

.curtxt {


    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */


    align-items: center;

    color: #9E9D9C;
}

.mdlsec1btnproceed {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;
    border: none;
    width: 189px;
    height: 59px;
    background: #F19202;
    border-radius: 14px;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;

    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.vatcont {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    align-items: flex-end;
    text-align: center;
    text-transform: capitalize;

    color: #F39200;
}

.calfooter {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #9E9D9C;
    padding: 5px 10px;
}

#calqut:hover {
    color: #F19202;
    background: rgba(241, 146, 2, 0.1);
    border-radius: 12px;
}

#odsum:hover {
    color: #F19202;
    background: rgba(241, 146, 2, 0.1);
    border-radius: 12px;
}

#pay:hover {
    color: #F19202;
    background: rgba(241, 146, 2, 0.1);
    border-radius: 12px;
}

/* 
.........................................Order Summary and payment details CSS Start...................... */

.ordersmry {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 52px;
    /* identical to box height */


    color: #000000;
}

.odsumsubtxt {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */


    color: #9E9D9C;
}

.odsumtbltxt {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    color: #9E9D9C;
}

.odsumtbtbodytxt {
    border-top: 1px solid #E9E9E9 !important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    color: #000000;
}

.sumtypetxt {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;


    color: #9E9D9C;
}

#md1 .modal-content {
    min-width: 1250px !important;

}

@media (min-width: 200px) and (max-width: 820px) {
    #md1 .modal-content {
        min-width: 0px !important;

    }
}

.gapdv {
    border-top: 1px solid #E9E9E9 !important;
    margin-top: 50px;
}

.dtatxt {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #000000;
}

.btnproceedpay {

    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    border: none;


    height: 69px;


    background: #F19202;
    border-radius: 18px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;

    align-items: center;
    text-align: center;

    color: #FFFFFF;
}


.paymentdet {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;

    /* identical to box height */


    color: #000000;
}

.paysub {

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;

    /* identical to box height */


    color: #9E9D9C;
}

.carddet {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;


    color: #111014;

    opacity: 0.6;
}

.formtextinp {
    border: 1px solid #000000;
    border-radius: 12px;
    height: 59px;
    width: 343px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    /* identical to box height, or 19px */


    /* Black */

    color: #000000;

}

.btnpaynow {

    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    border: none;

    width: 219px;
    height: 69px;


    background: #F19202;
    border-radius: 18px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.thanku {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    /* identical to box height */

    text-align: center;

    color: #000000;

}

.thankusub {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;

    color: #9E9D9C;
}

/* .........................................Order Summary CSS END...................... */

.available-action {
    display: flex;
    justify-content: flex-end;
}

.modaldcalculator {
    padding: 20px 20px 40px;
}

.dc-action {
    text-align: center;
}

.dynamiccal-info {
    margin-bottom: 110px;
}

.odsumtbtbodytxt tr td {
    vertical-align: middle;
}