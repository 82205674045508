.tableheadertextpurch {
  text-align: left;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  align-items: flex-end;

  text-transform: capitalize;
  color: #9e9d9c;
  border-bottom: 1px solid #e9e9e9;
}

.tablebodytextpurch {
  text-align: left;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;

  align-items: center;
}

.btnview {
  border: none;
  padding: 16px 24px;
  gap: 10px;
  background: #f19202;
  border-radius: 18px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}

.btnview.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.purchase-header {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 66px;
  color: #f19202;
}

.purchase-order {
  min-height: calc(100vh - 340px);
}
