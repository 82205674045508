.orange-text {
  color: #f39200;
}

.link {
  text-decoration: underline;
  font-size: 1rem;
  color: #f39200;
}

.link:hover {
  color: black;
}

.div-Dashboard {
  background: #ededed !important;
}

.managebookingtext {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
  /* identical to box height */

  color: #000000;
}

.viewall {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: right;

  color: #f19202;
}

.fttext {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #9e9d9c;
}

input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: var(--secondary);
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid var(--secondary);
  border-radius: 0.2em;
  display: inline-block;
  width: 25px;
  height: 25px;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 8px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  line-height: 23px;
}

input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

input[type="checkbox"]:checked + label:before {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
