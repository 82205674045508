.workshoptxt {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 12px;
  display: flex;
}

.backboxmain {
  background-color: #ffffff !important;
  background: radial-gradient(
    32.19% 89.87% at 100% 22.57%,
    rgba(255, 153, 1, 0.1) 0%,
    rgba(241, 146, 2, 0) 100%
  );
  border-radius: 28px;
  position: relative;
  overflow: hidden;
  padding: 0;
  min-height: 545px;
  display: flex;
  align-items: center;
}

.backbox1 {
  background: radial-gradient(
    32.19% 89.87% at 100% 22.57%,
    rgba(255, 153, 1, 0.1) 0%,
    rgba(241, 146, 2, 0) 100%
  );
}

.createmission {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 52px;
  text-align: center;
  color: #f19202;
}

.subtxt3 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #9e9d9c;
}

.persongroup {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  align-items: flex-end;
  color: #9e9d9c;
}

.timeapprox {
  text-align: right;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  align-items: flex-end;
  color: #9e9d9c;
}

.backboxmain .table-responsive table tr:first-child td div {
  margin-bottom: 12px;
}

.backboxmain .table-responsive table tr:first-child td {
  background: transparent;
}

.backboxmain .table-responsive table tr td {
  background: #f6f7f9;
}

.backboxmain .table-responsive table tr td:first-child {
  border-radius: 18px 0 0 18px;
}

.backboxmain .table-responsive table .notext {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.backboxmain .subtxt3 {
  max-width: 90%;
  display: flex;
  margin: 0 auto 40px;
}

.minicardback {
  height: 69px;
  background: #f9f9f9;
  border-radius: 18px;
}

.impnote {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #f19202;
}

.notetext1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #9e9d9c;
}

.backboxmain .table-responsive table {
  min-width: 1070px;
  margin: 0 auto;
}

.modal-proceed {
  padding-right: 10px;
}

.backboxmain .backboxmain-modal {
  width: 100%;
}

.backboxmain-modal .datePicker-column {
  min-height: 545px;
}

.backboxmain .datePickerandtime-row {
  border-radius: 0;
}

.backboxmain .Successfullybooked {
  padding-left: 40px;
  padding-right: 40px;
}

.workshop-active {
  position: absolute;
  margin: 0 auto;
  bottom: 30px;
  width: 100%;
}

.workshop-active .active {
  color: #f19202;
  background: rgba(241, 146, 2, 0.1);
  border-radius: 12px;
}

.importantNotice {
  margin-bottom: 60px;
}

.workshop_types {
  display: block;
  width: 100%;
  padding: 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.workshop_types .type_wrapper {
  width: 300px;
  margin: 5px 15px;
}
.workshop_types .type_wrapper.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.workshop_types .workshop_type {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.workshop_types .workshop_type + .type_heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-top: 8px;
}
.workshop_types .workshop_type + .type_heading + .type_desc {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #9e9d9c;
}
.workshop_types .workshop_type * {
  transition: all 300ms ease-in-out;
}
.workshop_types .workshop_type svg {
  display: block;
  height: 110px;
}

.workshop_types .workshop_type.self {
  background-color: rgba(241, 146, 2, 0.1);
}
.workshop_types .workshop_type.facilitated {
  background-color: #f2f2f2;
}
.workshop_types .workshop_type.self:hover,
.workshop_types .workshop_type.self.selected {
  background-color: var(--primary);
}
.workshop_types .workshop_type.self:hover path,
.workshop_types .workshop_type.self:hover circle,
.workshop_types .workshop_type.self.selected path,
.workshop_types .workshop_type.self.selected circle {
  stroke: white;
}
.workshop_types .workshop_type.facilitated:hover,
.workshop_types .workshop_type.facilitated.selected {
  background-color: black;
}
.workshop_types .workshop_type.facilitated:hover path,
.workshop_types .workshop_type.facilitated.selected path {
  stroke: white;

  stroke-opacity: 1;
  fill-opacity: 1;
}
.workshop_types .workshop_type.facilitated:hover path.fill_path,
.workshop_types .workshop_type.facilitated.selected path.fill_path {
  fill: white;
}

.facilitator_table {
  display: block;
  margin-top: 12px;
}
.facilitator_table + .bg-table .mdlsec1btnproceed {
  background-color: #000000;
  color: #ffffff;
}

table .orange {
  color: #f19202;
}
table .black {
  color: #000000;
}

.purchase_btns {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.purchase_btns button {
  outline: none;
  border: none;
  user-select: none;
  padding: 16px 24px;
  background: #f19202;
  border-radius: 14px;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin: 0 10px;
}
.purchase_btns button.no {
  background-color: rgba(246, 91, 91, 0.14);
  color: rgba(246, 91, 91, 1);
}
