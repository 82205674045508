.qty_field_container {
  width: 160px;
  padding: 8px 16px;
  background-color: #f6f7f9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.workshopcard .qty_field_container {
  margin: 0 auto;
}

.qty_field_value {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.qty_field_btns_container {
  display: flex;
}
.qtyinptxt {
  background: rgba(242, 166, 51, 0.25);
  border-radius: 12px;
  margin-right: 16px;
  text-align: center;
  width: 95px;
  border: none;
}
.qtyinptxt:focus {
  outline: none !important;
  border-color: #f19202;
}
.qty_field_btn {
  width: 36px;
  height: 36px;
  font-size: 24px;
  background-color: rgb(241 146 2 / 14%);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty_field_btn:first-child {
  margin-right: 12px;
}

.qty_field_btn.small {
  width: 26px;
  height: 26px;
  border: none;
}

.qty_field_btn.small svg {
  width: 11px;
}

/* HOVER */
.qty_field_btn:hover {
  background-color: #f19202;
}

.qty_field_btn:hover path {
  fill: #fff;
}

.qty_field_container.black .qtyinptxt,
.qty_field_container.black .qty_field_btn.small {
  background-color: rgba(0, 0, 0, 0.1);
}
.qty_field_container.black .qty_field_btn.small path {
  fill: #000000;
}
.qty_field_container.black .qty_field_btn.small:hover {
  background-color: #000000;
}
.qty_field_container.black .qty_field_btn.small:hover path {
  fill: #ffffff;
}
