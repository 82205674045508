.footertxt {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #9E9D9C;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .footertxt {
        width: max-content;
        text-align: inherit;
    }
}