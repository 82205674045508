.team_heading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .team_name {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    color: #f19202;

    .black {
      color: #000000;
    }
  }

  .resend_email {
    padding: 15px 10px;
    cursor: pointer;
    background-color: #f19202;
    color: #ffffff;
    outline: none;
    border: none;
    user-select: none;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;
    }
  }
}

.single_workshop {
  margin-bottom: 0;
}

.links_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 66px;
  color: #f19202;
}

.links_boxes {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .link_box {
    width: 50%;
    padding-top: 30px;
    padding-bottom: 30px;

    &.left {
      padding-right: 20px;
    }

    &.right {
      padding-left: 30px;
    }

    .link_heading {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #000000;
    }

    .link_info {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #aaaaaa;
    }

    .link_action_btn {
      padding: 16px 24px;
      background: rgba(241, 146, 2, 0.1);
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      color: #f19202;
      cursor: pointer;
      outline: none;
      border: none;
      user-select: none;
      border-radius: 18px;
      transition: all 300ms ease-in-out;

      &.thirty {
        width: 30%;
      }

      &.copied {
        background-color: #f19202;
        color: #ffffff;
      }
    }

    .copy_link_div {
      padding: 16px 24px;
      background: #f9f9f9;
      border-radius: 18px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 25px;
      color: #9e9d9c;
      text-overflow: clip;
      word-wrap: break-word;

      &.seventy {
        width: 65%;
      }
    }

    .link_action_div {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

.resend_popup {
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    text-align: center;
    color: #000000;
  }

  .info {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #9e9d9c;

    &.client_email {
      color: #f19202;
    }
  }

  .close_resendModal {
    display: block;
    margin: 10px auto;
    padding: 16px 24px;
    background: #f19202;
    border-radius: 14px;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    outline: none;
    border: none;
    user-select: none;
  }
}

.download_teamprofile {
  padding: 5px 10px;
  background: rgba(241, 146, 2, 0.1);
  border-radius: 12px;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #f19202;
  border: none;
  outline: none;
  cursor: pointer;
}

.total_participants_past_workshop {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  .black {
    color: #000000;
  }
}

.sub_info {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #9e9d9c;
}

.participants_list {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;

  .participant {
    padding: 6px 10px;
    background: rgba(229, 149, 54, 0.1);
    border-radius: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 119.22%;
    color: #e59536;
    margin-right: 12px;
  }
}

.co-mod {
  margin-top: 40px;
}
