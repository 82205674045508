@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --primary: #f19202;
  --primary-12: rgba(255, 153, 1, 0.12);
  --primary-14: rgba(241, 146, 2, 0.14);
  --primary-20: rgba(241, 146, 2, 0.2);
  --black: #000000;
  --secondary: #9e9d9c;
  --radius-main: 36px;
  --radius-main-mobile: 24px;
  --radius-middle: 18px;
  --radius-mini: 12px;
  --radius-micro: 7px;
  --highlight-red: #f10212;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Manrope";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background: #ffffff !important;
}

.container {
  max-width: 1200px;
}

.ReactModal__Overlay {
  background-color: rgb(0 0 0 / 80%) !important;
  z-index: 111;
}

.login-modal {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

.login-modal h1 {
  margin-bottom: 8px;
}

.login-modal p {
  margin-bottom: 26px;
}

.login-modal .proceedlink {
  position: relative;
}

.login-modal .backeffectpopup {
  max-width: 65%;
  padding: 5px 10px;
  height: auto;
  width: 100%;
  background: #f6f7f9;
  border-radius: 18px;
  display: flex;
  margin: 0 auto;
}

.requestModal {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

.requestModal h1 {
  margin-bottom: 8px;
}

.requestModal p {
  margin-bottom: 26px;
}

.requestModal .backeffectpopup {
  max-width: 65%;
  padding: 5px 10px;
  height: auto;
  width: 100%;
  background: #f6f7f9;
  border-radius: 18px;
  display: flex;
  margin: 0 auto;
}

.modalcodesent {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

.modalcodesent h1 {
  margin-bottom: 8px;
}

.modalcodesent p {
  margin-bottom: 26px;
}

.modalcodesent .poheadsubtxt {
  max-width: 85%;
  margin: 0 auto 20px;
  height: auto;
}

.stafhomepage {
  position: relative;
}

.stafhomepage .staf-manage-order {
  position: relative;
  z-index: 111;
}

.stafhomepage::before {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.05;
  transform: rotate(-26.28deg);
  content: "";
  position: absolute;
  right: -310px;
  top: -200px;
  width: 772px;
  height: 559.95px;
  z-index: -1;
}

.stafhomepage::after {
  position: absolute;
  left: -370px;
  content: "";
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.1;
  transform: rotate(-26.28deg);
  width: 772px;
  height: 559.95px;
  bottom: -150px;
  z-index: -1;
}

/* 'modal-delete */

.modal-delete {
  padding-top: 42px;
  padding-bottom: 42px;
}

.modal-delete h1 {
  margin-bottom: 8px;
}

.modal-delete p {
  margin-bottom: 34px;
}

.modal-delete .modal-action {
  max-width: 600px;
  margin: 0 auto;
}

.modal-workshop-deleted {
  padding-top: 42px;
  padding-bottom: 42px;
}

.modal-workshop-deleted .wrkshpdelsub.text-center {
  max-width: 800px;
  margin: 20px auto 0;
}

.table-responsive .upcomingworkshop {
  min-width: 600px;
}

.table-responsive table {
  min-width: 1170px;
}

.view-block {
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.viewall {
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
}

.workshopcard {
  padding-bottom: 30px;
}

.workshopcard h1 {
  margin-bottom: 29px;
}

.workshopcard {
  position: relative;
  overflow: hidden;
}

.workshopcard::before {
  width: 426.45000000000005px;
  height: 382.57px;
  right: -194px;
  top: -178px;
  position: absolute;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.05;
  transform: rotate(-26.28deg);
  content: "";
  pointer-events: none;
}

.workshopcard::after {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.1;
  transform: rotate(-26.28deg);
  content: "";
  width: 489.58px;
  height: 315.16px;
  position: absolute;
  left: -160px;
  bottom: -118px;
  pointer-events: none;
}

.workshopcard .row {
  position: relative;
  z-index: 111;
}

@media only screen and (max-width: 1024px) {
  .purchase-order {
    min-height: auto !important;
  }

  .purchaseHist {
    margin-bottom: 200px;
  }

  .requestModal .backeffectpopup {
    display: flex;
    max-width: 100%;
    justify-content: center;
  }

  .workshopcard {
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
  }

  .calfooter {
    display: flex;
  }

  .staf-manage-order .backeffect {
    height: auto;
  }

  .staf-manage-order .input-group {
    justify-content: center;
  }

  .staf-manage-order .input-group > .form-control {
    width: 100%;
    text-align: center;
  }

  .requestModal .backeffectpopup .input-group {
    flex-direction: column;
    align-items: center;
  }

  .requestModal .backeffectpopup .input-group > .form-control {
    width: 100%;
    text-align: center;
  }

  .button-action {
    flex-direction: column;
    align-items: center;
  }

  .confirmAccountModal .btndiscard {
    margin-bottom: 10px;
  }

  .checkbox-block {
    justify-content: flex-start !important;
  }

  .UpdateContact-Heading .my-account-btn {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .UpdateContact-Heading .account-title h1 {
    text-align: center;
  }

  .UpdateContact-Heading .account-title {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .UpdateContact .lastUpdatedRow {
    flex-direction: column;
  }

  .UpdateContact .lastUpdated {
    margin-bottom: 20px;
  }

  .logo-page {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .input-box {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .link-logout {
    margin-bottom: 20px;
    text-align: center;
  }

  .confirmSend .button-group {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .confirmSend .discardbutton {
    margin-bottom: 20px;
  }

  .timeslot-header {
    flex-direction: column;
  }

  .timeslot-header .timeslot-right {
    width: 100%;
  }

  .datePickerandtime-row {
    flex-direction: column;
  }

  .datePickerandtime-row .datePicker-column {
    width: 100%;
  }

  .datePickerandtime-row .timeslot-column {
    width: 100%;
  }

  .datePickerandtime-row .mission-details-block {
    max-width: 100%;
  }

  .backboxmain .workshop-active {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .backboxmain .createmission {
    padding-left: 15px;
    padding-right: 15px;
  }

  .backboxmain .table-responsive {
    padding: 25px;
  }

  .backboxmain-modal .timeslot-list {
    flex-wrap: wrap;
    flex-flow: wrap;
    height: auto;
  }

  .backcard .businessx {
    margin: 0 auto 20px;
  }

  .backcard .AccountDetails {
    justify-content: center;
  }

  .ReactModal__Overlay .timeslot-list-header {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .ReactModal__Overlay .timeslot-list {
    flex-flow: wrap;
  }

  .ReactModal__Overlay .timeslot-list .timeslot {
    min-width: 130px;
  }

  .WorkshopDeleted {
    padding: 20px;
  }

  .moldaDeleteBooking {
    padding: 20px;
  }

  .moldaDeleteBooking .button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .keepbtn {
    margin-bottom: 10px;
  }

  .language-list {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .purchlisbackcard .available-action {
    padding: 20px;
    justify-content: center;
  }

  .purchlisbackcard .available-licenses-number {
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
  }

  .ReactModal__Overlay {
    overflow-y: auto !important;
    display: flex;
    justify-content: center;
  }

  .ReactModal__Content {
    transform: none !important;
    inset: auto !important;
    margin-top: 100px !important;
    bottom: 10px !important;
  }

  .login-modal .input-group {
    flex-direction: column;
    flex-wrap: inherit;
  }

  .login-modal .backeffectpopup {
    height: auto;
    max-width: 90%;
    background-color: #ffffff;
  }

  .login-modal .input-group > .form-control,
  .login-modal .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 90%;
    min-width: 0;
    margin: 0 auto 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .login-modal
    .input-group:not(.has-validation)
    > .dropdown-toggle:nth-last-child(n + 3),
  .login-modal
    .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .login-modal .input-group-append {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-modal .proceedlink {
    position: relative;
    top: 3px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* modal */

.language-modal {
  padding: 24px;
}

.la-modal-header {
  margin-bottom: 34px;
}

.la-modal-header h2 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 46px;
  text-align: center;
  color: #f19202;
  margin: 0;
  margin-top: 0;
}

.la-modal-content {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #9e9d9c;
}

.modal-back a {
  display: flex;
  align-items: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.modal-back .back_btn {
  margin-right: 12px;
}

.language-list {
  display: flex;
  margin-top: 34px;
  margin-bottom: 34px;
  justify-content: space-between;
}

.language-box {
  min-width: 215px;
  background: rgba(244, 244, 244, 0.8);
  border-radius: 18px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.language-box * {
  cursor: pointer;
}

.language-box label {
  margin-left: 13px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 41px;
  color: #000000;
}

.language-box {
  margin-bottom: 10px;
}
.language-box.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.language-box.disabled * {
  cursor: not-allowed;
}

.modal-notifaction {
  width: 100%;
  padding: 34px;
}

.modal-notifaction h1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 66px;
  margin: 0;
  text-align: center;
  color: #000000;
  margin-bottom: 8px;
}

.modal-notifaction p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #9e9d9c;
}

.modal-notifaction p.secondInfo {
  margin-top: 30px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
}

.modal-footer button {
  min-width: 176px;
  height: 59px;
  margin-left: 10px;
  margin-right: 10px;
}

.Successfullybooked p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #9e9d9c;
}
.Successfullybooked p.orange {
  color: var(--primary);
}

.successfully-header {
  margin-bottom: 30px;
}

.Successfullybooked a {
  color: #f19202;
}

.successfully-title {
  display: flex;
  align-items: center;
}

.successfully-title h1 {
  margin-bottom: 0px;
  margin-left: 20px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-mini);
  font-weight: 800;
  cursor: pointer;
  padding: 10px 29px;
  border: none;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary);
}

.btn-secondary {
  color: var(--primary);
  background-color: var(--primary-20);
}

.btn-secondary:hover {
  background-color: var(--primary);
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: none;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.button-action {
  display: flex;
  justify-content: center;
}

.copy-action {
  cursor: pointer;
}

.close-modal {
  cursor: pointer;
  position: absolute;
  right: 34px;
  top: 34px;
}

.UpdatedWorkshop {
  padding: 50px 40px;
}

.UpdatedWorkshop h1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  color: #f19202;
  margin-bottom: 10px;
}

.UpdatedWorkshop p.first-info {
  ont-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #575756;
}

.UpdatedWorkshop p.second-info {
  font-family: "Manrope";
  font-style: normal;
  font-size: 22px;
  line-height: 33px;
  color: #575756;
}

.UpdatedWorkshop .close-modal {
  right: 28px;
  top: 33px;
}

.modalUnavailable {
  padding: 50px 40px;
}

.modalUnavailable h1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  color: #f19202;
  margin-bottom: 10px;
}

.modalUnavailable p.first-info {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #575756;
}

.modalUnavailable p.second-info {
  font-family: "Manrope";
  font-style: normal;
  font-size: 20px;
  line-height: 33px;
  color: #575756;
}

.modalUnavailable p.second-info a {
  color: #f19202;
}

.modalUnavailable .close-modal {
  right: 28px;
  top: 33px;
}

.modaldcalculator {
  position: relative;
}

.dynamicCal .table-responsive table {
  min-width: 850px;
  max-width: 850px;
  margin: 0 auto;
}

.table-dynamic-calculator {
  max-width: 1000px;
  margin: 0 auto;
}

.proceed-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-dynamic-calculator {
  margin-top: 28px;
  margin-bottom: 20px;
}

.table-dynamic-calculator table th {
  text-align: center;
}

.table-dynamic-calculator table td {
  text-align: center;
}

.table-dynamic-calculator table td .mdlsec1subtxt {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.vatcont-block {
  text-align: center;
  margin-bottom: 80px;
}

.dynamiccal-section h1 {
  margin-top: 80px;
}

.dynamic_cal_footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
}
.dynamic_cal_footer .left_cal {
  max-width: 60%;
}

.table-dynamic-calculator tr th {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #9e9d9c;
}
.three_column_table tbody tr td {
  width: 33%;
}

.table-dynamic-calculator tr td:first-child {
  text-align: left;
}

.table-dynamic-calculator tr td:last-child {
  /* max-width: 150px; */
  border-radius: 0px 12px 12px 0px;
  overflow: hidden;
  text-align: right;
  padding-right: 20px;
}

.bg-table {
  background: #f6f7f9;
  overflow: hidden;
}

.bg-table td:first-child {
  border-radius: 12px 0px 0px 12px;
  overflow: hidden;
}

.bg-table td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-lable td {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #9e9d9c;
  padding-bottom: 10px;
}

.Order-confirmation-price-block {
  border-top: solid 1px #e9e9e9;
  padding-top: 24px;
  margin-top: 20px;
}

.Order-confirmation-price-block {
  display: flex;
  justify-content: space-between;
}

.Order-confirmation-price-items {
  min-width: 23%;
}

.Order-confirmation-value-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.Ocon-value-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #9e9d9c;
  max-width: 300px;
}

.Ocon-value {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}

.text-right {
  justify-content: flex-end;
}

.Order-confirmation-price-items:nth-child(3) {
  width: 100px;
  min-width: 100px;
}

.Order-confirmation-price-items:nth-child(4) {
  width: 250px;
  min-width: 200px;
}

.Order-confirmation-price-items:nth-child(4) .btn {
  width: 200px;
  min-width: 200px;
  padding: 15px 17px;
}

input[type="checkbox"] + label {
  display: block;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: var(--secondary);
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #9d9d9d;
  border-radius: 0.2em;
  display: inline-block;
  width: 25px;
  height: 25px;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 8px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  line-height: 23px;
}

input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

input[type="checkbox"]:checked + label:before {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.checkbox-block {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-start;
}

.paymentDetailsheader {
  margin-bottom: 26px;
}

.cardDetails {
  margin-left: 60px;
}

.cardDetails h1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  color: #111014;
  opacity: 0.6;
  margin-bottom: 14px;
}

.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}

.did-floating-label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 122%;
  color: #7c7d82;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 16px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
  display: block;
  height: 50px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 12px;
  min-width: 320px;
  max-width: 343px;
  width: 100%;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.did-floating-input:focus,
.did-floating-select:focus {
  border: 1px solid #000000 !important;
  background: #fff !important;
}

.did-floating-input:focus ~ .did-floating-label,
.did-floating-select:focus ~ .did-floating-label {
  top: 3px;
  font-size: 12px;
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: 3px;
  font-size: 12px;
}

.did-floating-input:focus::placeholder {
  color: transparent;
}

.did-floating-input::placeholder {
  color: transparent;
}

.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-select[value=""]:focus ~ .did-floating-label {
  top: 11px;
  font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input .did-floating-input,
.did-error-input .did-floating-select {
  border: 2px solid #9d3b3b;
  color: #9d3b3b;
}

.did-error-input .did-floating-label {
  font-weight: 600;
  color: #9d3b3b;
}

.did-error-input .did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}

.input-group {
  display: flex;
}

.input-group .did-floating-input {
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 0;
}

.input-group-append {
  display: flex;
  align-items: center;
  /*   margin-left:-1px; */
}

.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 34px;
  color: #323840;
  padding: 0 5px 0 20px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #3d85d8;
  border-radius: 4px 0 0 4px;
  border-right: none;
}

.cardDetails-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pay-button {
  margin-right: 100px;
  margin-bottom: 100px;
}

.bg-modal-licenses {
  position: relative;
  overflow: hidden;
}

.bg-modal-licenses::before {
  position: absolute;
  width: 772px;
  height: 500px;
  left: 653px;
  top: 29.75px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.05;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

.thsnkyou-modal {
  padding: 70px !important;
}

.thsnkyou-modal .thankusub {
  font-size: 18px;
}

.thsnkyou-modal .thankusub p {
  margin-bottom: 0px;
}

.dc-action .active {
  color: #f19202;
  background: rgba(241, 146, 2, 0.1);
  border-radius: 12px;
}

.footertxt a {
  color: #9e9d9c;
}

#root .home-page {
  position: relative;
  overflow: hidden;
}

#root .home-page::after {
  position: absolute;
  width: 772px;
  height: 559.95px;
  left: 803px;
  top: 1940.75px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.1;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

#root .home-page::before {
  position: absolute;
  width: 772px;
  height: 559.95px;
  left: 755px;
  top: -155.25px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.05;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

#root .home-page.update-form-page::before {
  position: absolute;
  width: 772px;
  height: 559.95px;
  left: 755px;
  top: -155.25px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.05;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

#root .home-page.update-form-page::after {
  position: absolute;
  width: 652px;
  height: 626.95px;
  left: -159px;
  top: 555.75px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.1;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

#root .home-page.purchase-page::before {
  position: absolute;
  width: 772px;
  height: 559.95px;
  left: 755px;
  top: -155.25px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.05;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

#root .home-page.purchase-page::after {
  position: absolute;
  width: 652px;
  height: 626.95px;
  left: -159px;
  top: 330.75px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.1;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

#root .home-page.new-account::before {
  position: absolute;
  width: 772px;
  height: 559.95px;
  left: 755px;
  top: -155.25px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.05;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

#root .home-page.new-account::after {
  position: absolute;
  width: 652px;
  height: 626.95px;
  left: -159px;
  top: 781.75px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.1;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

.home-page {
  position: relative;
  z-index: 1;
}

.home-page section {
  position: relative;
  z-index: 1;
}

.manageExisting-header {
  position: relative;
  z-index: 11;
}

.available-licenses-card {
  z-index: 11;
  position: relative;
}

.moldaDeleteBooking {
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 289px;
}

.moldaDeleteBooking h2 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: normal;
  text-align: center;
  color: #000000;
}

.moldaDeleteBooking p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  color: #9e9d9c;
  margin-bottom: 20px;
}

.moldaDeleteBooking .button-group {
  max-width: 500px;
  margin: 0 auto;
}

.WorkshopDeleted {
  padding-top: 40px;
  padding-bottom: 40px;
}

.deleteicon-block {
  cursor: pointer;
}

.table-responsive .tablebodycolumn1 td .btnres {
  margin: 0 auto;
}

.tablebodycolumn1 .qty_field_container {
  margin: 0 auto;
}

.staff-section {
  margin-top: 60px;
}

.staff-section .unlockButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accountUnlocked {
  padding: 40px;
  text-align: center;
}

.confirmSend {
  padding: 40px;
  text-align: center;
}

.confirmSend .button-group {
  max-width: 690px;
  margin: 0 auto;
}

.prospects-list {
  margin-top: 33px;
}

#root .staf-page.home-page::after {
  position: absolute;
  width: 772px;
  height: 661.95px;
  left: -228px;
  top: 401.75px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f19202 0%,
    rgba(241, 146, 2, 0) 100%
  );
  opacity: 0.1;
  transform: rotate(-26.28deg);
  content: "";
  z-index: -1;
}

.input_field {
  background-color: #f6f7f9;
  border-radius: 12px;
  padding: 19px 28px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--secondary);
  width: 100%;
  box-shadow: none;
  border: none;
}

.input_field.number {
  padding: 12px 05px;
}

.input_field:focus-visible {
  outline-color: var(--primary);
}

.input_field::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.input_field::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.input_field:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.input_field:-moz-placeholder {
  /* Firefox 18- */
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.lastUpdated {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.lastUpdatedRow {
  display: flex;
  justify-content: flex-end;
}

.lastUpdatedRow .btnsave {
  margin-left: 24px;
  background: rgba(241, 146, 2, 0.1);
  border-radius: 18px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f19202;
  min-width: 175px;
  min-height: 58px;
  border: none;
  justify-content: center;
}

.modalddeleteBox {
  padding: 40px;
  text-align: center;
}

.modalddeleteBox h1 {
  margin-bottom: 8px;
}

.modalddeleteBox p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #9e9d9c;
}

.deleteAccount-action {
  display: flex;
  justify-content: center;
}

.deleteAccount-action .nokeep {
  background: #f19202;
  border-radius: 14px;
  width: 176px;
  height: 59px;
  border: none;
  color: #ffffff;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.deleteAccount-action .yesDelete {
  background: rgba(246, 91, 91, 0.14);
  border-radius: 14px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f65b5b;
  border: none;
  width: 176px;
  height: 59px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
}

.purchaseHist td {
  vertical-align: middle;
  text-align: center;
}

.purchaseHist td:nth-child(1) {
  text-align: left;
}

.purchaseHist td:nth-child(6) {
  text-align: center;
}

.purchaseHist .btnview-action {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control:focus {
  box-shadow: none;
  background-color: #f6f7f9;
}

.number-block {
  margin-right: 10px;
}

.number-block .input_field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  width: 83px;
  background: rgba(242, 166, 51, 0.25);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  color: #000;
}

.number-block .input_field::-webkit-input-placeholder {
  /* Edge */
  color: rgb(0, 0, 0);
  text-align: center;
}

.number-block .input_field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(0, 0, 0);
  text-align: center;
}

.number-block .input_field::placeholder {
  color: rgb(0, 0, 0);
  text-align: center;
}

.error {
  color: red;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background-image: url(data:image/svg+xml;utf8,<svg width=%2720%27 height=%2713%27 viewBox=%270 0 20 13%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27><path opacity=%270.5%27 d=%27M2 2L10 10L18 2%27 stroke=%27black%27 stroke-width=%273%27/></svg>);
  background-position-x: 96%;
  background-position-y: 50%;
  background-repeat: no-repeat;
}
