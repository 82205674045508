a:hover {
    color: #F19202;
    text-decoration: none;
}

.staf-manage-order {
    min-height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.staf-manage-container {
    margin-top: 60px;
    margin-bottom: 60px;
}

.stafhomepage .container {
    margin-top: 0px !important;
}

.clickhere {
    color: #F19202;
    height: 25px;
    left: 752px;
    top: 540px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    /* text-transform: capitalize; */
}

.btn-primary {
    background-color: #f39200;
    border: 1px solid #f39200 !important;
    color: #fff;
}

.sectionStyle {
    padding-top: 111px;
}

.hero-2-title-h {
    color: #f39200;
}

.hero-2-title-h-3 {
    color: #000;
}

.Procced-button {
    color: white;
    background-color: #f39200;
    border: none;
    margin-top: 5px;
    padding: 10px 20px;
    border-radius: 10px;
    margin-left: 2px !important;
    margin-bottom: 5px !important;

}

.proceedlink:hover {
    color: #FFFFFF;

}

.proceedlink {
    border: none;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    width: 185px;
    background: #F19202;
    border-radius: 14px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    text-align: center;
}

.footer-home {
    bottom: 0;
    position: relative;
    margin-top: 155px;
}

.textboxpcode {
    min-width: 55%;
    max-width: 100%;
    height: 69px;
    background: #F6F7F9;
    border-radius: 18px;
    border: none;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #9E9D9C;
    padding-left: 0px;
    text-shadow: 0.25px 0 0 #f6f7f9;
}

.maincontentblock {
    width: 941px;
}

.headertext {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    color: #F19202;
}

.subtext {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #9E9D9C;
}

.displaytext {
    margin-top: 10px;
    height: 25px;
    left: 752px;
    top: 540px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: #9E9D9C;
}

.textareadiv {
    height: 69px;
    left: 594px;
    top: 459px;
}

.textarea {
    width: 732px;
    height: 69px;
    left: 594px;
    top: 459px;
    background: #F6F7F9;
    border-radius: 18px;
}


#txtcode:focus {
    outline: none;
    background-color: #F6F7F9 !important;
    border-color: #F6F7F9 !important;
    box-shadow: none !important;
}

.forgotcust {
    max-width: 417px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    /* text-transform: capitalize; */
    color: #9E9D9C;
}



.blureffecttop {
    background: radial-gradient(50% 50% at 50% 50%, #f19202 0, rgba(241, 146, 2, 0) 100%);
    opacity: .1;
    pointer-events: none;
    position: absolute;
    -webkit-transform: matrix(-.9, -.44, -.44, .9, 0, 0);
    transform: matrix(-.9, -.44, -.44, .9, 0, 0);
    width: 200px;
    left: 1270px;
    top: -33.25px;
}


.blureffect {
    background: radial-gradient(50% 50% at 50% 50%, #f19202 0, rgba(241, 146, 2, 0) 100%);
    opacity: .1;
    pointer-events: none;
    position: absolute;
    transform: matrix(-.9, -.44, -.44, .9, 0, 0);
    width: 50vw;
    top: 315px;
    left: -425px;
}

.backeffect {
    padding-right: 5px;
    height: 69px;
    background: #F6F7F9;
    border-radius: 18px;
    display: flex;
}

.backeffectpopup {
    max-width: 65%;
    padding-right: 5px;
    height: 69px;
    width: 100%;
    background: #F6F7F9;
    border-radius: 18px;
    display: flex;
}

@media (max-width: 768px) {
    .staf-manage-order {
        min-height: auto;
    }

    .staf-manage-order h1.headertext {
        font-size: 40px;
        margin-top: 40px;
        line-height: normal;
    }

    .stafhomepage {
        overflow: auto;
        height: auto;
    }

}

@media (min-width: 200px) and (max-width: 768px) {
    .backeffectpopup {
        max-width: 100%;
        padding-right: 5px;
        height: 69px;
        width: 100%;
        background: #F6F7F9;
        border-radius: 18px;
        display: flex;
    }
}


.staf-manage-order h1.headertext {
    text-align: center;
    margin-bottom: 20px;
}

.staf-manage-order .subtext {
    text-align: center;
    margin-bottom: 37px;
}

.staf-manage-order .backeffect {
    max-width: 470px;
    text-align: center;
    margin: 0 auto 12px;
}

.staf-manage-order .input-group {
    padding-left: 10px;
    padding-right: 10px;
}

.staf-manage-order .forgotcust {
    margin: 0 auto 0px;
}