.search-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;

    opacity: 0.6;
}

.form-group {
    margin-left: 20%;
}

.Searchbox {
    width: 80%;
    padding-left: 55px;
    background: #F6F7F9;
    border-radius: 12px;
    border: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    height: 59px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 119.22%;
    align-items: flex-end;
    color: #111014;
    opacity: 0.6;
}

.logout-button {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    height: 50px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 119.22%;
    align-items: center;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.quantity-btn {
    margin: 0px 5px;
    font-size: 20px;
    color: #f39200;
    font-weight: bold;
    background-color: #ffedb0;
    backdrop-filter: blur(9px);
    border-radius: 50%;
    padding: 0px 10px;
}

.button-group {
    display: flex;
    justify-content: space-evenly;
    margin-top: -5px;
    margin-left: 5px;
}

.btn-res {
    border: none;
    font-weight: bolder;
    color: #f39200;
    background-color: #ffedb0;
    border-radius: 10px;
}

.delete-icon {
    background-color: #ffddd6;
    border-radius: 50%;
    padding: 6px 6px;
}

.logo {
    width: 123.6px;
    height: 74.21px;
    left: 316px;
    top: 10px;
}

.input-box {
    position: relative;
}

.searchicon {
    position: absolute;
    top: 17px;
    color: gray;
    z-index: 1;
    margin-left: 23px;
}

.alignright {
    text-align: right;
}

@media (min-width: 200px) and (max-width: 820px) {
    .alignright {
        text-align: left;
    }
}

/* ...........................new css............ */