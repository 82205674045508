.my-account-btn {
    display: flex;
    justify-content: flex-end;
}

.my-account-btn .btnpurc {
    min-width: 227px;
    min-height: 58px;
    background: #F19202;
    border-radius: 12px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-account-btn .btnpurc:hover {
    color: #fff;
}

.account-title {
    text-align: left;
}

.account-title h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    color: #F19202;
    margin-bottom: 0;
}

.subheading {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #9E9D9C;
    margin-top: 4px;
    margin-bottom: 33px;
}

.ccode{
    min-height: 65px;
    max-width: 90px;
    padding: 19px 16px;
}

.lastupdate {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #9E9D9C;
    justify-content: flex-end;
    align-items: flex-end;
}

.deleteprofile {
    /* width: 138px; */
    background: rgba(246, 91, 91, 0.1);
    border-radius: 8px;
    color: #F65B5B;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    border: none;
}

.modalDeletedBox {
    text-align: center;
    padding: 40px;
}

.modalDeletedBox h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    text-align: center;
    color: #000000;
}

.modalDeletedBox p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #9E9D9C;
}

.modalDeletedBox p:last-child {
    margin-bottom: 0px;
}

.modalDeletedBox a {
    color: #F19202;
}

.purchase-page .container {
    margin-top: 0 !important;
}