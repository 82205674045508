.orange-text {
  color: #f39200;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

#mission {
  width: 174px;
  height: 33px;
  left: 23px;
  top: 340px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

#team {
  width: 186px;
  height: 25px;
  left: 23px;
  top: 373px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #1E1E1C;
}

#reschedule-input {
  padding-left: 10px;
  width: 334px;
  height: 54px;
  left: 23px;
  top: 410px;
  background: #F6F7F9;
  border-radius: 18px;
  border: 0px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #9E9D9C;

}

#team-note {
  width: 90%;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
  color: white;
  font-size: 12px;
}

.time-container {
  background-color: #fff;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 15px;
  padding-left: 30px;
}

.grey-button {
  width: 100%;
  border: none;
  margin: 5px 0px;
  padding: 10px 25px;

  background: #E9E9E9;
  border-radius: 10px;
  left: 0%;
  right: 0%;
  top: 4.17%;
  bottom: 4.17%;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.22px;
  color: #575756;
}

.white-button {
  /* border: 1px solid black;
  margin: 5px 0px;
  padding: 10px 25px;
  border-radius: 8px;
  max-width: 115px;
  white-space: nowrap; */
  border: none;
  border-radius: 10px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.22px;

  /* Gray / 50 */

  color: #7A7585;
}

.orange-button {
  background-color: #ffedb0;
  color: #f39200;
  border: none;
  margin: 5px 0px;
  padding: 10px 25px;
  border-radius: 8px;
  max-width: 115px;
  white-space: nowrap;
}

#confirm-button {
  border: none;
  color: white;
  background-color: #f39200;
  border-radius: 8px;
  margin: 5px 0px;
  padding: 10px 25px;
  max-width: 115px;
  white-space: nowrap;
}

.time-area {
  padding-bottom: 20px;
}

.calendar-area {
  padding: 0;
  margin: 0;
  background-color: #F19202;
  border-radius: 36px 0px 0px 36px;
  height: 552px;
  margin-top: -18px;
  margin-left: -5px;
  position: absolute;
}

/* OVERRIDING STYLES FROM REACT-CALENDAR */

.react-calendar {

  background: #F19202;
  border: none;
  line-height: 1.125em;

  padding-left: 10px;
  border: none;
  width: 300px;
  margin-top: 3rem;
  height: 316px;
  left: 23px;
  right: 23px;
  top: 24px;

}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1rem;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: white;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  left: 24px;
  right: 264px;
  top: 60px;
  bottom: 240px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #000000;
}

.react-calendar__month-view__days {
  left: 0%;
  right: 0%;
  top: 15.62%;
  bottom: 15.62%;

  /* IBM Plex Sans / Subtitle 2 */

  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.22px;

  color: #3C3844;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;

  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: white;
  border-radius: 50%;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: white;
  border-radius: 50%;
}

.react-calendar__tile--hasActive {
  background: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: white;
}

.react-calendar__tile--active {
  background: white;
  color: black;
  border-radius: 50%;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}



.cal {
  position: absolute;
  width: 380px;
  height: 552px;
  left: 0px;
  top: 0px;
  background: #F19202;
  border-radius: 36px 0px 0px 36px;
}

.calmodalbody {
  position: relative;
  width: 1100px;
  height: 552px;
  background: #FFFFFF;
  border-radius: 36px;
}

.h6hdtext {
  width: 650px;
  height: 38px;
  left: 426px;
  top: 29px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #F19202;
}

.head3 {
  width: 557px;
  height: 25px;
  left: 426px;
  top: 71px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */


  color: #9E9D9C;
}

.berlintext {
  width: 221.11px;
  height: 23.74px;
  left: 426px;
  top: 116px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}

.dttimeaval {
  width: 234.83px;
  height: 25.89px;
  left: 963.97px;
  top: 116px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: right;

  color: #F19202;
}
.pop1{
  
font-family: 'Manrope';
font-style: normal;
font-weight: 500;
font-size: 48px;
line-height: 66px;
/* identical to box height */


color: #F19202;
}
.pop2{
  left: 0%;
right: 0%;
top: 30.96%;
bottom: 0%;

font-family: 'Manrope';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 33px;

color: #9E9D9C;

}
.emailtxt{
  color: #F19202;;
}
.modalcontwidt{
  width: 900px;
}
/* ...............................new css........................... */


.e1147_1514 {
  width: 650px;
  height: 67px;
  /* position:absolute; */
}

.e1147_1515 {
  color: rgba(241.00000083446503, 146.00000649690628, 2.000000118277967, 1);
  width: 650px;
  height: 38px;
  /* position:absolute; */
  left: 0px;
  top: 0px;
  font-family: Manrope;
  text-align: left;
  font-size: 28px;
  letter-spacing: 0;
}

.e1147_1516 {
  color: rgba(158.00000578165054, 157.0000058412552, 156.00000590085983, 1);
  width: 557px;
  height: 25px;
  /* position:absolute; */
  left: 0px;
  top: 42px;
  font-family: Manrope;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0;
}