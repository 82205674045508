.orange-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 66px;
  /* identical to box height */

  color: #f19202;
}

.twotxt {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 66px;
  /* identical to box height */

  text-align: right;

  color: #000000;
}

.wrkshptxt {
  vertical-align: super;
  padding-bottom: 8px;
  padding-left: 10px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  background-image: url("https://booking-portal-ui.vercel.app/static/media/line.a8d786ff70759697f51aae1c79815a8b.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-position: 15px 30px;
  color: #9e9d9c;
}

.viewsubtxt {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #9e9d9c;
}

.workshopPastUpcoming {
  display: flex;
  width: fit-content;
  padding: 8px 10px;
  gap: 12px;
  background: #f6f7f9;
  border-radius: 48px;
}
.workshopPastUpcoming .period_btn {
  padding: 8px 14px;
  border-radius: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  outline: none;
  border: none;
  color: #f19202;
  background-color: transparent;
  transition: all 300ms ease-in-out;
}
.workshopPastUpcoming .period_btn.selected,
.workshopPastUpcoming .period_btn:hover {
  color: #ffffff;
  background: #f19202;
}
