.table > tbody {
  vertical-align: bottom;
}

.nav-tabs {
  border-bottom: 0px;
}

#manageaccount:hover {
  color: #fff;
  text-decoration: none;
}

#createaccountgrid:hover {
  color: #fff;
  text-decoration: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f19202 !important;
  background-color: #fff;
  background-image: url("https://booking-portal-ui.vercel.app/static/media/line.a8d786ff70759697f51aae1c79815a8b.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 0px solid transparent;
}

.tab {
  border: none;
  background-color: transparent;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 55px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #9e9d9c !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.CreatenewAccount {
  width: max-content;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: rgba(241, 146, 2, 0.1);
  border-radius: 12px;
  border: 0px !important;
  margin-top: 15px !important;
  color: #f19202;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
}

.CreatenewAccount span {
  margin-right: 05px;
}

.paratext {
  margin-top: 35px;
  height: 33px;
  left: 317px;
  top: 253px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #9e9d9c;
}

.clienttable {
  margin-top: 10px;
  width: 100%;
}

.tableheadertext {
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  align-items: flex-end;
  text-transform: capitalize;
  color: #9e9d9c;
  border-bottom: 1px solid #e9e9e9;
}

.tablebodytext {
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  align-items: center;
}

.buttonunlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 112px;
  height: 47px;
  background: rgba(241, 146, 2, 0.1);
  border-radius: 8px;
  border: 0px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f19202;
}

.tablebodycont {
  background: rgba(242, 165, 50, 0.25);
  padding: 5px 5px;
  margin: 10px;
  opacity: 1;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.modalshow {
  position: relative;
  width: 901px;
  height: 281px;
  background: #ffffff;
  box-shadow: 12px 16px 24px rgba(0, 0, 0, 0.07);
  border-radius: 36px;
}

.modeltext {
  left: 127px;
  top: 44px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 66px;
  text-align: center;
  color: #000000;
}

.modeltext2 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #9e9d9c;
}

.spantext {
  font-weight: bold;
  color: #000000;
}

.discardbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  border: 0px !important;
  width: 260px;
  height: 59px;
  color: #f19202;
  background: rgba(241, 146, 2, 0.1);
  border-radius: 14px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
}

.confirmbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  border: 0px !important;
  width: 260px;
  height: 59px;
  color: #ffffff;
  background: #f19202;
  border-radius: 14px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.spanaccountunlocked {
  color: #f19202;
}

.managaccountbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f19202;
  border-radius: 12px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  min-height: 47px;
  border: none;
}

.prospectparatext {
  width: 437px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #9e9d9c;
}

.setdivht {
  height: 30% !important;
}

.liscencepricebackdiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 0px;
  background: #f6f7f9;
  border-radius: 8px;
  max-width: 260px;
  margin: 0 auto;
  padding: 3px 5px;
}

.chfpricebox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  width: 83px;
  background: rgba(242, 166, 51, 0.25);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.chfbacktext {
  width: 47px;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #9e9d9c;
  flex-grow: 0;
}

.btntxt {
  background: #f6f7f9;
  border: none;
  width: 92px;
  height: 27px;

  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #f19202;
  flex: none;
}

.liscencepricebackdiv.facilitator {
  background: rgba(246, 247, 249, 1);
}
.liscencepricebackdiv.facilitator .number-block .input_field {
  background-color: rgba(0, 0, 0, 0.1);
}
.liscencepricebackdiv.facilitator .btntxt {
  color: #000000;
}

.payLater {
  display: block;
  background-color: rgba(229, 232, 237, 1);
  width: 56px;
  height: 30px;
  position: relative;
  border-radius: 111px;
  margin: 0 auto;
  cursor: pointer;
}
.payLater::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  width: 24px;
  height: 24px;
  background: #7c7d82;
  border-radius: 111px;
  transition: all 300ms ease-in-out;
}
.payLater.on::before {
  background-color: #f19202;
  transform: translateX(26px);
}

.locked_status {
  display: block;
  margin: 0 auto;
  width: 38px;
  height: 41px;
  border-radius: 8px;
  background-color: rgba(241, 146, 2, 0.1);
  transition: all 300ms ease-in-out;
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
  user-select: none;
}
.locked_status svg path {
  stroke: #f19202;
  transition: all 300ms ease-in-out;
}
.locked_status.locked {
  background-color: rgba(216, 0, 39, 0.1);
  border: none;
  outline: none;
  user-select: none;
}
.locked_status.locked svg path {
  stroke: rgba(216, 0, 39, 1);
  transition: all 300ms ease-in-out;
}

.staff-header .staff-button {
  margin-bottom: 33px;
}

.staff-header .paratext {
  margin-bottom: 33px;
  margin-top: 0px;
}

.clients-isted tr td {
  vertical-align: middle;
}

.clients-isted tr td:nth-child(1) {
  min-width: 130px;
  text-align: left;
}

.clients-isted tr td:nth-child(2) {
  min-width: 120px;
  text-align: center;
}

.clients-isted tr td:nth-child(3) {
  min-width: 120px;
  text-align: center;
}

.clients-isted tr td:nth-child(4) {
  min-width: 150px;
  text-align: center;
}

.clients-isted tr td:nth-child(5) {
  min-width: 160px;
  text-align: center;
}

.clients-isted tr td:nth-child(6) {
  min-width: 200px;
  text-align: center;
}

.prospects-list tr td:nth-child(1) {
  min-width: 130px;
  text-align: left;
}

.prospects-list tr td:nth-child(4) {
  min-width: 130px;
  text-align: center;
}

.prospects-list tr td:nth-child(5) {
  min-width: 130px;
  width: 220px;
  text-align: right;
}

.prospects-list .create-button {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.prospects-list td {
  vertical-align: middle;
}

.prospects-list .managaccountbutton {
  max-width: 100%;
  width: max-content;
  min-width: 210px;
  border: none;
}
