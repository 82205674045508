.goback-button {
    margin-bottom: 40px;
    margin-top: 40px;
    display: flex;
}

#goback:hover {
    color: #9E9D9C !important;
    text-decoration: none;
}
.ccode{
    min-height: 65px;
    max-width: 90px;
    padding: 19px 16px;
}
.gobacktext {
    padding-left: 5px;
    height: 27px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #9E9D9C;
}

.createNewClient-header h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: normal;
    color: #F19202;
    margin-bottom: 4px;
}

.createNewClient-header p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #9E9D9C;
}

.set-back {
    background: #F6F7F9;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.set-back .pricebox {
    background: rgba(242, 166, 51, 0.25);
    border-radius: 8px;
    color: #000000;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    padding: 4px 20px;
    margin-right: 10px;
}

.currencytext {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #9E9D9C;
}

.price-box {
    display: flex;
    align-items: center;
}

.set-back .set {
    background: rgba(241, 146, 2, 0.1);
    border-radius: 8px;
    width: 176px;
    height: 45px;
    color: #F19202;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    border: none;
}

.priceperlis {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: flex-end;
    color: #9E9D9C;
    margin-top: 10px;
}

.accountconfirm {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #000000;
}

.createaccount {
    background: #F19202;
    border-radius: 16px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    border: none;
    height: 53px;
    padding: 0 18px;
}

.account-Confirmation input[type=checkbox]+label {
    font-size: 15px;
    position: relative;
    padding-right: 40px;
}

.account-Confirmation input[type=checkbox]+label:before {
    right: 0;
    position: absolute;
}

.account-Confirmation .checkbox-block {
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 0;
}

.confirmAccountModal {
    padding: 40px 20px;
}

.confirmAccountModal h1 {
    margin-bottom: 8px;
}

.confirmAccountModal p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #9E9D9C;
    max-width: 700px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.confirmAccountModal .btndiscard {
    background: rgba(246, 91, 91, 0.1);
    border-radius: 14px;
    width: 260px;
    height: 49px;
    border: none;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #F65B5B;
    margin-left: 10px;
    margin-right: 10px;
}

.confirmAccountModal .btncon {
    background: #F19202;
    border-radius: 14px;
    width: 260px;
    height: 49px;
    border: none;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
}